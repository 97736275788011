
<template>

<div>
    <v-container id="dashboard" fluid tag="section">
        <v-text-field class="mt-4" :label="$t('datatable.write_to_search')" outlined append-icon="mdi-magnify" v-model="search">
        </v-text-field>
        <v-data-table :headers="headers" :items="desserts" sort-by="phone" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title> {{ $t("Drawer.subjects") }} </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="890px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" @click="editedIndex = -1 " dark class="mb-2" v-bind="attrs"
                                v-on="on">
                                {{ $t("add_new") }}
                            </v-btn>
                        </template>
                        <v-stepper v-model="e1">
                          <v-stepper-header>
                                <v-stepper-step editable :complete="e1 > 1" v-if="editedIndex > -1" step="1"
                                    style="    color: #fff;">
                                    {{formTitle}}
                                </v-stepper-step>

                                <v-stepper-step :complete="e1 > 1" v-else step="1" style="    color: #fff;">
                                    {{formTitle}}
                                </v-stepper-step>

                                <v-divider></v-divider>




                                <v-divider></v-divider>


                            </v-stepper-header>
                         
                           

                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-card class="mb-12" flat>

                                        <v-form ref="form" v-model="valid">

                                            <v-card-text>
                                                <v-container>

                                                    <v-row>
                                                        <!-- title -->
                                                        
                                                        <v-col class="py-0" cols="12" sm="12" md="12">
                                                            <v-textarea v-model="editedItem.title" :rules="messageRules"  required
                                                                :label="$t('datatable.title')"
                                                                outlined>
                                                            </v-textarea>
                                                        </v-col>
                                                     <!-- more -->
                                    
                                                        <v-col class="py-0" cols="12" sm="12" md="12">
                                                            <v-textarea v-model="editedItem.more.ar" :rules="messageRules"  required
                                                                label="التفاصيل ar"
                                                                outlined>
                                                            </v-textarea>
                                                        </v-col>



                                                        <v-col class="py-0" cols="12" sm="12" md="12">
                                                            <v-textarea v-model="editedItem.more.en" 
                                                                label="التفاصيل en"
                                                                outlined>
                                                            </v-textarea>
                                                        </v-col>

                                                        <!-- downloads -->
                                                        
                                                        <!-- <v-col class="py-0" cols="12" sm="12" md="12">
                                                            <v-textarea v-model="editedItem.downloads" :rules="messageRules"  required
                                                                :label="$t('datatable.downloads')"
                                                                outlined>
                                                            </v-textarea>
                                                        </v-col>

                                                        
                                                        <v-col class="py-0" cols="12" sm="12" md="12">
                                                            <v-textarea v-model="editedItem.views" :rules="messageRules"  required
                                                                :label="$t('datatable.views')"
                                                                outlined>
                                                            </v-textarea>
                                                        </v-col> -->
                                                        
                                                        <v-col class="py-0" cols="12" sm="12" md="12">
                              
                                                          <v-container fluid>
                              
                                                            <v-col class="d-flex" cols="12" sm="6">
                              
                                                              <v-select v-model="editedItem.sections_id" required :rules="select"
                              
                                                              item-value="id" item-text="title.ar" :items="category" label="Sections">
                              
                                                            </v-select>
                              
                                                          </v-col>
                              
                                                        </v-container>
                            
                                                      </v-col>

                                                      <v-col class="py-0" cols="6" sm="12" md="6">
                                                            <v-text-field :label="$t('Drawer.choose_img')"
                                                                @click='pickImg' v-model='imageName'
                                                                prepend-icon='fas fa-paperclip'>
                                                            </v-text-field>
                                                            <input type="file" style="display: none" ref="image"
                                                                accept="image/*" @change="onFilePicked">

                                                        </v-col>


                                                        <v-col class="py-0" cols="6" sm="6" md="6">
                                                            <v-img :src="imageUrl" height="150"
                                                                :lazy-src="thumb_small" v-if="imageUrl" />
                                                        </v-col>



                                                    </v-row>
                                                    <v-row class="mt-8">
                                                        <v-file-input  v-model='zipFile' label="ملفات ال (zip) 3D " accept="application/zip" @change="onFileChange" />
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>

                                        </v-form>
                                    </v-card>

                                    <v-btn color="primary" :loading="loadSave" @click="save()">
                                        {{$t("save")}}
                                    </v-btn>

                                    <v-btn text @click="close()">
                                        {{$t("close")}}
                                    </v-btn>

                                </v-stepper-content>
                             
                               
                            </v-stepper-items>
                        </v-stepper>
                    </v-dialog>











<v-dialog v-model="dialog2" max-width="890px">
  

  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step editable step="2" style="color: #fff;">
        {{formTitle}}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="2">
        <v-card class="mb-12" flat>

          <v-form ref="form" v-model="valid" lazy-validation>



            <v-card-text>
              <v-container>
                <v-row>
                <!-- spec -->
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field  v-model="inputItem.spec.ar" :rules="messageRules" required
                      :label="' الوصف '+$t('ar')" outlined>
                    </v-text-field>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field v-model="inputItem.spec.en" 
                      :label="' الوصف '+$t('en')" outlined>
                    </v-text-field>
                  </v-col>



                  <!-- val -->
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field v-model="inputItem.val.ar" :rules="messageRules" required
                      :label="' القيمة '+$t('ar')" outlined>
                    </v-text-field>
                  </v-col>

                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field v-model="inputItem.val.en" 
                      :label="' القيمة '+$t('en')" outlined>
                    </v-text-field>
                  </v-col>


                
                  <v-col class="py-0" cols="12" sm="12" md="12">
                    <v-text-field disabled :rules="messageRules" required
                      label="id" v-model="editedItem.id" outlined>
                    </v-text-field>
                  </v-col>
               



                </v-row>
              </v-container>
            </v-card-text>

          </v-form>
        </v-card>

        <v-btn color="primary" :loading="loadSave" @click="saveInput()">
          {{$t("save")}}
        </v-btn>

      </v-stepper-content>


    </v-stepper-items>
  </v-stepper>
</v-dialog>









                </v-toolbar>
            </template>


































            <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text @click="editItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on"> {{ $t("edit") }}</v-btn>
                    </template>
                    <span>{{ $t("edit") }} </span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text @click="addInputs(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on"> {{$t("Drawer.inputsSubects")}}</v-btn>
                    </template>
                    <span>{{$t("Drawer.inputsSubects")}}</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn text @click="deleteItem(item)" v-if="!item.isDeleted" v-bind="attrs" v-on="on">
                            {{$t('Delete')}}</v-btn>

                    </template>
                    <span>{{$t('Delete')}}</span>
                </v-tooltip>



            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
            </template>
        </v-data-table>
    </v-container>
</div>
</template>


<script>
import Axios from "axios";
import Swal from "sweetalert2";
export default {
    data() {
        return {
            desserts: [

            ],
            category: [],
            cats: [],
            dialog: false,
            dialog2: false,
            loadSave: false,
            file_name: null,
            e1: 1,

            imagesUrl: '',
            imageName: '',
            zip: '',
            show_loading: false,
            imageUrl: '',
            img_old: '',
            imageFile: '',
            zipFile: '',
            x:this.$i18n.locale,

            imagesName: ' ',
            editedIndex: -1,
            editedItem: {
                images:[],
                image: {
                    image_name: ''
                },
                id: "",
                title:"",
                more: {
                    ar: "",
                    en: ""
                },
                views:"",
                downloads:"",
                sections_id:"",
              },



            inputItem: {
            id: "",
            val: {
              ar: "",
              en: ""
            },
            spec: {
              ar: "",
              en: ""
            },
            },

            items: [],
        
            right: null,
            vvv: {},
            messageRules:[
            v => !!v || this.$t("valMessage")
        ],
        }
    },

    watch:{
            '$i18n.locale': function(newVal, oldVal) {
            
            this.x=newVal;
            this.get();  
  },  },
    methods: {
        get(){
                return this.headers= [{
                        text: '#ID',
                        align: "start",
                        value: "id"
                    },

                    {
                        text: this.$t('datatable.title'),
                        align: "start",
                        value:  "title"
                    },
                                       {
                        text: this.$t('Processes'),
                        value: "actions",
                        sortable: false
                    }
                ];
            },
    validate () {
    valid: true;
    },
        editItem(item) {
            this.e1 = 1;
            this.editedIndex = this.desserts.indexOf(item);
            this.$store.dispatch("art_uploudess", item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            this.img_old = ''
            this.imageName = ''
            this.zip = ''
            this.imageFile = null
            this.zipFile = null
            this.imageUrl = ''

           this.img_old = this.editedItem['photo'];
           this.imageUrl = this.resUrl+'/upload/large/270/' + this.img_old;

        },

        addInputs (item) {
            this.dialog2 = true;
            this.e1 = 2;
            this.editedIndex = this.desserts.indexOf(item);
            this.$store.dispatch("book_uploads", item);
            this.editedItem = Object.assign({}, item);


        },

        pickFile() {
            this.$refs.file.click()
        },

        onPickfile(e) {

            this.file = this.$refs.file.files[0];
            let file = e.target.files;
            this.file = file[0]
            this.file_name = file[0].name
        },


        close() {
           
            this.e1 = 1;
            this.loadSave=false;
            this.img_old = ''
            this.imageName = ''
            this.zip = ''
            this.zipFile = ''
            this.imageFile = ''
            this.imageUrl = ''
            this.dialog = false;
            this.editedItem = {
                images:[],
                image: {
                    image_name: ''
                },
                id: "",
                title:"",
                more: {
                    ar: "",
                    en: ""
                },
                views:"",
                downloads:"",
                sections_id:"",
           
            }

            this.$refs.form.resetValidation();
            this.$store.dispatch("art_uploudess", this.vvv);
            this.$store.dispatch("book_uploads", this.vvv);
          
        },

      getCats() {

        Axios.get("/sectionsSelected", {
        headers: {
         "Content-Type": "application/json",
         Accept: "application/json",
         authorizations: "Bearer " + this.$store.state.AdminInfo.token
         } 
       })
      .then(res => {
      this.category = res.data.data;
      this.loading = false;
      }).catch(() => {
        this.loading = false;
         });
            },

        initialize() {
            this.loading = true;
            Axios.get("subjects", {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    } 
                })
                .then(res => {
                    this.loading = false;
                    this.desserts = res.data.data;

                })
                .catch(() => {
                    this.loading = false;
                });
        },

        pickImg() {
            this.$refs.image.click()
        },


        pickFiles() {
            this.$refs.images.click()

        },

        onFileChange(event) {
            const file = event;
            if (file !== undefined) {
                this.zip = file.name
                this.zipFile = file
                
            }
        },

        onFilePicked(e) {

            const files = e.target.files

            if (files[0] !== undefined) {

                this.imageName = files[0].name

                if (this.imageName.lastIndexOf('.') <= 0) {
                    return
                }


                const fr = new FileReader()
                fr.readAsDataURL(files[0])
                fr.addEventListener('load', () => {

                    this.imageUrl = fr.result
                    this.imageFile = files[0]


                })
            } else {
                // this.imageName = ''
                // this.imageFile = ''
                // this.imageUrl = ''


            }
        },


        deleteItem(item) {

            Swal.fire({
                title: this.$t('sure_process'),
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            }).then(result => {
                if (result.value) {
                    Axios.delete("subjects/" + item.id, {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                authorizations: "Bearer " + this.$store.state.AdminInfo.token
                            }
                        })
                        .then(() => {
                            this.initialize();
                            this.$swal.fire(this.$t('Successfully'), this.$t('done'), "success");

                        })
                        .catch(() => {
                            this.$swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                        });
                }
            });
        },
       
        save() {
         if(this.$refs.form.validate()){


            if (this.imageFile == '') {
                this.$swal.fire({
                    title: "يجب اختيار صوره",
                    text: "",
                    icon: "error",
                    confirmButtonText: "اغلاق",
                });

            } else {
                this.loadSave = true;
                if (this.editedIndex > -1) {

                    var update = {
                      
                          
                           title: this.editedItem.title,
                           translations: [{

                                more:this.editedItem.more.ar,  
                                          
                                },
                            {

                                more:this.editedItem.more.en,    
                            
                            }],
                            
                            sections_id  : this.editedItem.sections_id,
                            views :this.editedItem.views,
                            downloads : this.editedItem.downloads,

                    }
                    this.axios
                        .put("subjects/update/" + this.editedItem.id,update, {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                            },
                        })
                        .then(() => {

                            if (this.zip != null) {
                                let formData = new FormData();
                                formData.append('zip', this.zipFile);
                                Axios.post('subjects/uploadImage/' + this.editedItem.id,
                                    formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                            Accept: "application/json",
                                            authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                        },
                                    }
                                )
                            }
                            if (this.imageFile != null) {
                                let formData = new FormData();
                                // const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                                formData.append('photo', this.imageFile);
                                Axios.post('subjects/uploadImage/' + this.editedItem.id,
                                    formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                            Accept: "application/json",
                                            authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                        },
                                    }
                                )
                                this.img_old = ''
                                this.imageName = ''
                                this.zip = ''
                                this.imageFile = ''
                                this.zipFile = ''
                                this.imageUrl = ''
                            }
                            this.$refs.form.resetValidation();
                            this.initialize();

                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                            this.close();

                        })
                        .catch(() => {
                            this.loadSave = false;
                            this.$swal.fire({
                                title: "تاكد من ملى المعلومات",
                                text: "",
                                icon: "error",
                                confirmButtonText: "اغلاق",
                            });
                        });
                } else {

                      var savedate = {
                        title: this.editedItem.title,
                           translations: [{

                                more:this.editedItem.more.ar,  
                                          
                                },
                            {

                                more:this.editedItem.more.en,    
                            
                            }],
                            
                            sections_id  : this.editedItem.sections_id,
                            views :this.editedItem.views,
                            downloads : this.editedItem.downloads,

                    }


                    this.axios
                        .post("subjects", savedate, {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                            },
                        })
                        .then((res) => {
                         

                            this.editedItem = res.data.data;

                            if (this.zip != null) {
                                let formData = new FormData();
                                formData.append('zip', this.zipFile);
                                Axios.post('subjects/uploadImage/' + this.editedItem.id,
                                    formData, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                            Accept: "application/json",
                                            authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                                        },
                                    }
                                )
                            }
                            
                            if (this.imageFile != null) {
                                let formData = new FormData();
                                // const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                                formData.append('photo', this.imageFile);
                                Axios.post('subjects/uploadImage/' + this.editedItem.id,
                                        formData, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                                Accept: "application/json",
                                                authorizations: "Bearer " +this.$store.state.AdminInfo.token
                                            }
                                        }
                                    )
                                    .then(response => {
                                        response

                                        this.e1 = 2
                                        this.loadSave = false;
                                        this.img_old = ''
                                        this.imageName = ''
                                        this.zip = ''
                                        this.imageFile=''
                                        this.zipFile=''
                                       
                                        // }
                                    })
                                    
                                // this.img_old = ''
                                
                                this.editedIndex = -1;
                                this.loadSave = false;
                                this.imageName = ''
                                this.zip = ''
                                this.zipFile=''

                                // this.initialize();
                            }
                             this.$swal.fire({
                                            title: 'تم رفع ',
                                            text: "",
                                            icon: "success",
                                            confirmButtonText: this.$t('close'),
                                        });
                                
                                this.$refs.form.resetValidation();
                                this.initialize();
                                this.close();
                        })
                        .catch((err) => {
                            err
                            /// this.loadSave = false;
                        });
                }
            }
            }

        },


        saveInput() {
  
        if (this.$refs.form.validate()) {

                this.show_loading=true;
                var savedate = {

                translations: [{
                    val: this.inputItem.val.ar,
                    spec: this.inputItem.spec.ar,

                    },
                    {
                    val: this.inputItem.val.en,
                    spec: this.inputItem.spec.en,

                    }
                ],
                subjects_id: this.editedItem.id,

                }

                this.axios.post("inputsSubjects", savedate, {
                    headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + this.$store.state.AdminInfo.token,
                    },
                })
                .then((res) => {
                    this.editedItem = res.data.data;
                    this.editedIndex = -1;
                    this.$refs.form.resetValidation();

                    this.dialog = false;
                                this.loadSave = false;
                        
                                this.show_loading=false;
                                this.close();
                                this.initialize();
                                Swal.fire({
                    title: "تم الرفع ",
                    text: "",
                    icon: "success",
                    confirmButtonText: "اغلاق",
                });
                
                })

                .catch((err) => {
                    err

                    // this.loadSave = false;

                });

            }
        },

    },

    components: {

    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? this.$t('add_new') : this.$t('update');

        },
    },
    created() {
        this.initialize();
        this.get();
        this.getCats();
        
    },

}
</script>